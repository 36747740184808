.homepage {

	h1 {
		text-transform: uppercase;
	}

	// Each section title
	.homepage-section-title {
		text-align: center;

		h2 {
			font-weight: normal;
			font-size: 2.5rem;

			small {
				color: currentColor;
				display: block;
				text-transform: uppercase;
				.open;
				font-weight: 400;
				margin-bottom: 10px;
				font-size: 57%;
			}
		}
	}

	// Bloc 1 : Les derniers carnets de voyages
	.bloc-1 {
		.listing {
			margin-top: 40px;
		}
	}

	// Bloc 2 : Idées voyages;
	.bloc-2 {
		.main-carousel {
			margin-top: 50px;

			.carousel-cell {
				width: 100%;
				min-height:70vh;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;

				.overlay {
					background-color: rgba(0,0,0,0.15);
				}

				p:not(.link) {
					padding: 0 20px;
					color: white;
					text-shadow:1px 1px 1px black;
					text-align: center;
					font-size: 2.2rem;
				}

				h2 {
					padding: 0 20px;
					color: white;
					text-transform: uppercase;
					text-shadow:1px 1px 1px black;
					margin:30px 0;
					text-align: center;
					font-size: 2.8rem;
				}
			}
		}
	}

	// Bloc 3 : Regards de voyageurs;
	.bloc-3 {
		section {
			margin-top: 50px;
			min-height: 70vh;

			.overlay {
				background-color: rgba(0,0,0,0.15);
			}

			.wrap-last-regards {
				background-color: rgba(255,255,255,0.9);
				box-shadow: 3px 3px 0px 0px rgba(0,0,0,0.3);
				padding: 20px;
				margin: 40px auto 0;
				max-width: 500px;

				ul {
					li {
						padding:15px 0;
						border-top: 1px solid @graye0;

						&:nth-child(1) {
							border-top-width: 0;
							padding-top: 0;
						}

						&:last-of-type {
							padding-bottom: 0
						}
					}

					a {
						display: block;

						&:hover {
							.title {
								color: @green;
							}
						}
					}
				}

				.title {
					color: black;
					font-size: 1.7rem;
					line-height:1.2;
					font-weight: 600;
					._transition;
				}

				.infos {
					em {
						color: @gray6f;
						font-size: 1.4rem;
					}

					span {
						font-size: 1.4rem;
					}
				}

				// a.btn {
				// 	margin-top: 40px;
				// 	width: 100%;
				// }
			}

			.right {
				margin-top: 40px;

				p {
					padding: 0 20px;
					color: white;
					text-shadow:1px 1px 1px black;
					text-align: center;
					font-size: 2.2rem;
					line-height:1.2
				}
			}
		}
	}

	// Screen > 767px;
	._media-s-up({
		// Each section title
		.homepage-section-title {
			h2 {
				font-size: 3rem;
			}
		}

		// Bloc 2 : Idées voyages;
		.bloc-2 {
			.main-carousel {
				.carousel-cell {
					p:not(.link) {
						font-size: 3.2rem;
					}

					h2 {
						font-size: 4rem;
					}
				}
			}
		}
	});

	// Screen > 989px;
	._media-m-up({
		// Bloc 2 : Idées voyages;
		.bloc-2 {
			.main-carousel {
				.carousel-cell {
					p:not(.link) {
						font-size: 5rem;
						padding:0;
					}

					h2 {
						max-width:850px;
						margin: 40px auto;
						font-size: 5.5rem;
						padding:0;
					}
				}
			}
		}

		// Bloc 3 : Regards de voyageurs;
		.bloc-3 {
			section {
				.cc-inside {
					display: flex;
					align-items: flex-end;
					min-height:~"calc(70vh - 180px)";
				}

				.left {
					flex: 0 0 430px;
					order: -1;
				}

				.wrap-last-regards {
					padding: 30px ;
					margin: 0;
					
					.title {
						font-size: 2rem;
						._truncate(360px);
					}

					.infos {
						span,
						em {
							font-size: 1.5rem;
						}
					}
				}

				.right {
					flex: 1;
					margin-top: 0;
					align-self: center;

					p {
						padding: 0 0 0 30px;
						font-size: 3.5rem;
					}
				}
			}
		}
	});

	// 
	._media-l-up({
		// Bloc 3 : Regards de voyageurs;
		.bloc-3 {
			section {
				.left {
					flex: 0 0 500px;
				}

				.wrap-last-regards {
					padding: 50px 40px;

					.title {
						._truncate(410px);
					}
				}

				.right {
					p {
						padding: 0 0 0 100px;
					}
				}
			}
		}
	});
}
// Modal Glossary in post pages
#modal-glossary {
  height: 100%;

  .modal-content {
    width: 100vw;
    height:100%;
    max-height: 100%;
  }

  .modal-header {
    border:0;
    position: relative;
    text-transform: none;
    text-align: center;

    a {
      display: block;
      padding:10px;
      position: absolute;
      top:5px;
      left:5px;
      font-size: 3rem;
      z-index:2;

      i {
        display: block;
        width:30px;
        height: 30px;
      }
    }

    p {
      font-size: 2.5rem;
      text-align: center;
      text-transform: uppercase;

      &.h2-like {
        padding-top:20px;
      }

      &.satisfy {
        font-size: 2rem;
        text-transform: none;
      }
    }
  }

  .modal-body {
    padding:0 20px 20px;
  }

  .modal-footer {
    display: none;
    border:0;

    a {
      margin-top: 30px;
      font-weight: 600;
      text-transform: uppercase;
      padding-left: 30px;
      padding-right: 30px;
      font-size: 1.8rem;
    }
  }


  // Screen > 767px;
  ._media-s-up({
    .modal-content {
      width:70%;
    }

    .modal-header {
      p {
        font-size: 3rem;

        &.satisfy {
          font-size: 2.5rem;
        }
      }

    }

    .modal-body {
      padding:0 30px;
    }

    .modal-footer {
      display: block;
    }
  });


  // Screen > 989px
  ._media-m-up({
    .modal-content {
      width:50%;
    }

    .modal-header {
      p {
        font-size: 3rem;
      }
    }
  });


  // Screen > 1199px
  ._media-l-up({
    .modal-header {
      p.satisfy {
        font-size: 3rem;
      }
    }
  });
}


// Glossary
#glossary-list {
  display: flex;
  flex-wrap: wrap;

  li {
    flex:0 0 48%;
    margin:0 1%;
  }

  a {
    display: block;
    font-weight: 400;
    text-align: center;
    ._transition;
  }

  .glossary-number {
    overflow: hidden;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    border-bottom: 1px solid @graye0;
  
    span {
      display: block;
      font-weight: 900;
      font-size: 4rem;
      color: @graye0;
      position: relative;
      bottom: -24px;
      ._transition;
    }
  }

  .glossary-name {
    font-style: italic;
    font-size: 1.6rem;
  }

  // Screen > 767px
  ._media-s-up({
    .glossary-number {
      span {
        font-size: 6rem;
        bottom: -33px;
      }
    }
  });

  // Screen > 767px
  ._media-m-up({
    li {
      flex:0 0 30%;
      margin: 0 1.5%;
    }

    .glossary-number {

      span {
        font-size: 7rem;
        bottom: -38px;
      }
    }
  });
}
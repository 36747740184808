// MAIN HEADER
#main-header {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  z-index: 9;
  top: 0;
  left: 0;
  right: 0;
  width: 100vw;
  ._transition(0.2s, background-color);

  // body.opened-menu
  .sps--blw &,
  .opened-menu & {
    background-color: rgba(0, 0, 0, 0.9);
  }

  .wrap-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;

    // Logo
    & > a {
      img {
        max-height: 80px;
      }
    }
  }

  // Screen > 989px
  ._media-m-up({
    // body.opened-menu
    .opened-menu & {
      background-color: rgba(0, 0, 0, 0.5);
    }

    .wrap-header {
      align-items: stretch;
      padding: 0 0 0 20px;
    }
  });

  // Screen > 1199px
  ._media-l-up({
    .wrap-header {
      // Logo
      & > a {
        img {
          max-height: 100px;
        }
      }
    }
  });
}

// MAIN MENU
#main-nav {
  overflow-y: auto;
  position: fixed;
  top: 80px;
  left: -120%;
  z-index: 8;
  height: ~"calc(100% - 80px)";
  width: 100vw;
  padding: 10px 20px 20px;
  background-color: rgba(0, 0, 0, 0.9);
  ._transition(0.35s, left, cubic-bezier(1, 0, 0, 1));

  & > * {
    color: white;
  }

  // body : opened-menu et sub-menu
  .opened-submenu &,
  .opened-menu & {
    left: 0%;
  }

  // Search form
  form {
    display: flex;
    flex-wrap: wrap;
    padding-top: 10px;

    label {
      flex: 0 0 100%;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 1.6rem;
      padding: 0 0 5px 0;
    }

    input {
      flex: 1;
      background-color: black;
      border-color: black;
      color: white;

      &::-moz-placeholder {
        color: #555555;
        font-style: italic;
        font-weight: 400
      }

      &::-ms-input-placeholder {
        color: #555555;
        font-style: italic;
        font-weight: 400
      }

      &::-webkit-input-placeholder {
        color: #555555;
        font-style: italic;
        font-weight: 400
      }
    }

    input,
    button {
      width: inherit;
      margin: 0;
      border-radius: 0;
    }
  }

  // Main menu list
  & > ul {
    & > li {
      border-top: 1px solid #444444;

      a {
        font-size: 1.6rem;
        color: white;
        text-transform: uppercase;
        padding: 10px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      // Plus...
      &.more-links-item {
        & > span {
          display: none;
        }

        ul {
          display: block;

          li {
            border-top: 1px solid #444444;

            &:nth-child(1) {
              border-top-width: 0
            }
          }
        }
      }

      // Search form
      &.search-form-item {
        padding-bottom: 30px;
        border-top-width: 0px;

        label {
          display: none;
        }

        & > a {
          display: none;
        }
      }

      // Comptoirs
      &.comptoirs > div {
        overflow-y: auto;
        position: fixed;
        top: 80px;
        left: -120%;
        height: ~"calc(100% - 80px)";
        width: 100vw;
        padding: 10px 20px 20px;
        background-color: rgba(0, 0, 0, 0.9);
        ._transition(0.35s, left, cubic-bezier(1, 0, 0, 1));

        &.shown {
          left: 0%;
          background-color: rgba(0, 0, 0, 1);
        }

        .cc-inside {
          padding: 0;
        }

        .comptoir-label a {
          justify-content: flex-start;

          i {
            margin-right: 20px;
          }
        }

        .comptoir-title {
          text-transform: uppercase;
          color: white;
          font-size: 1.6rem;
          font-weight: 700;
          border-bottom: 1px solid #444444;
          padding-bottom: 3px;
          margin-bottom: 5px;
        }

        .countries-list {
          a {
            padding: 3px 0;
            font-size: 1.6rem;
            text-transform: none;
          }
        }
      }
    }
  }

  // Screen > 989px
  ._media-m-up({
    position: static;
    height: inherit;
    width: inherit;
    padding: 0;
    background-color: transparent;
    transition: inherit;
    overflow: inherit;
    margin-right: 15px; // Scrollbar

    & > ul {
      display: flex;
      flex-wrap: wrap;
      height: 100%;

      & > li {
        padding: 0;
        border: 0;
        display: flex;
        align-items: center;
        position: relative;

        &.comptoirs {
          &:after {
            display: block;
            content: ' ';
            height: 0px;
            width: 1px;
            background-color: white;
            position: absolute;
            left: 50%;
            top: ~"calc(100% - 20px)";
            ._transition;
          }
        }

        // Hover
        &:hover {
          & > a,
          & > span {
            background-color: rgba(0, 0, 0, 0.6);
            ._transition;
          }

          &:after {
            height: 40px;
          }

          div {
            display: block;
          }
        }

        span,
        a {
          font-size: 1.4rem;
          padding: 0 15px;
          height: 100%;

          i {
            display: none;
          }
        }

        div {
          display: none;
        }

        // Search form
        &.search-form-item {
          padding-bottom: 0;
          order: 2;

          & > a {
            display: flex;
            padding: 0 30px;

            i {
              display: block;
            }
          }

          div {
            position: absolute;
            top: 100%;
            right: 0;
            background-color: rgba(0, 0, 0, 0.8);
            padding: 15px 20px;
            min-width: 420px;
          }

          form {
            label {
              display: block;
            }

            button {
              padding: 0 20px;
            }
          }
        }

        // Plus...
        &.more-links-item {
          & > span {
            display: flex;
            align-items: center;
            justify-content: space-between;
            text-transform: uppercase;

            i {
              display: block;
            }
          }

          div {
            position: absolute;
            top: 100%;
            right: 0;
            background-color: rgba(0, 0, 0, 0.8);
            padding: 35px 20px;
          }

          ul {
            min-width: 190px;

            li {
              border: 0;

              a {
                text-transform: none;
                font-size: 1.6rem;
                display: block;
                padding: 0;
                text-align: right;
              }
            }
          }
        }


        // Comptoirs
        &.comptoirs > div {
          overflow-y: auto;
          left: 0;
          height: inherit;
          padding: 0px;
          background-color: transparent;
          ._transition;

          .cc-inside {
            background-color: rgba(0, 0, 0, 0.8);
            display: flex;
            padding: 35px 40px;

            // body.opened-menu
            .sps--blw &,
            .opened-menu & {
              background-color: rgba(0, 0, 0, 0.95);
            }

            & > li {
              flex: 0 0 20%;
              padding-top: 0;
            }
          }


          .comptoir-label {
            display: none;
          }

          .comptoir-title {
            border-bottom: 0;
          }

          .countries-list {
            a {
              display: block;
              padding: 0;
            }
          }
        }
      }
    }
  });

  // Screen > 1199px
  @media screen and (min-width: 1199px) and (max-width: 1250px) {
    & > ul {
      & > li {
        span,
        a {
          font-size: 1.4rem !important;
          padding: 0 15px !important;
        }
      }
    }
  }

  ._media-l-up({
    & > ul {
      & > li {
        span,
        a {
          font-size: 1.5rem;
          padding: 0 30px;
        }

        // Comptoirs
        &.comptoirs > div {
          top: 100px;

          .cc-inside {
            margin: 0 0 0 auto;
          }
        }
      }
    }
  });
}

// FOOTER
#main-footer {
  position: relative;
  z-index: 2;
  background-color: @gray;

  // Newsletter
  .wrap-newsletter {
    position: relative;
    top: -40px;

    .cc-inside {
      position: relative;
      z-index: 2;
    }

    header {
      text-align: center;

      .h1-like {
        text-transform: uppercase;
        color: white;
      }

      .satisfy {
        color: white;
        font-size: 1.9rem
      }
    }


    form {
      max-width: 710px;
      margin: 20px auto;
      padding: 0 20px;

      input {
        display: block;
        width: 100%;

        /* Placeholder color */

        &::-moz-placeholder {
          color: @text[mc];
          font-style: italic;
        }

        &::-ms-input-placeholder {
          color: @text[mc];
          font-style: italic;
        }

        &::-webkit-input-placeholder {
          color: @text[mc];
          font-style: italic;
        }

        &.cc-hidden {
          display: none;
        }
      }

      button {
        display: block;
        margin: 0;
        width: 100%;
        font-weight: 700;
      }
    }


    .alert {
      max-width: 670px;
      margin: 30px auto;
    }
  }

  // After newsletter : About, links, contact
  .wrap-links {
    padding-bottom: 40px;
    padding-top: 0;

    .title {
      text-transform: uppercase;
      padding-bottom: 10px;
      font-weight: 600;
      font-size: 2rem;
      color: black;
      text-align: center;
    }

    .wrapper > div {
      padding-top: 30px;
      color: black;
      font-size: 1.5rem;
      font-weight: 400;
      text-align: center;

      a {
        display: block;
        color: black;
        ._transition;

        &:hover {
          color: @primary;
        }
      }

      &:nth-child(1) {
        padding-top: 0;
      }
    }


    a[href^="tel"] {
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        height: 11px;
        margin-right: 10px;
      }
    }

    .social-icons {
      padding-top: 15px;
      display: flex;
      justify-content: center;

      a {
        padding: 10px;
        font-size: 2rem;
      }
    }
  }

  // Credits et Mentions
  .credits {
    font-size: 1.5rem;
    color: white;
    text-align: center;
    font-weight: 400;

    a {
      font-size: 1.5rem;
      color: white;
    }
  }


  // Screen > 767px
  ._media-s-up({
    // Newsletter
    .wrap-newsletter {
      header {
        padding-bottom: 40px;

        .satisfy {
          font-size: 2.5rem;
        }
      }

      form {
        display: flex;

        input {
          flex: 1;
        }

        button {
          flex: 0 0 30%;
        }
      }
    }

    // After newsletter : About, links, contact
    .wrap-links {
      .title {
        text-align: left;
      }

      .wrapper {
        display: flex;
        flex-wrap: wrap;

        & > div {
          flex: 0 0 33%;
          padding: 0px 20px 0 0;
          text-align: left;

          &.about {
            flex: 0 0 100%;
            padding-bottom: 40px;
          }
        }
      }


      a[href^="tel"] {
        justify-content: flex-start;

      }

      .social-icons {
        justify-content: flex-start;
      }
    }
  });

  // Screen > 989px
  ._media-m-up({
    // Newsletter
    .wrap-newsletter {
      header {
        .satisfy {
          font-size: 3rem;
        }
      }
    }
  });

  ._media-l-up({
    // After newsletter : About, links, contact
    .wrap-links {
      padding-bottom: 80px;

      .wrapper {
        justify-content: center;

        & > div {
          flex: 1;

          &.about {
            flex: 0 0 350px;
            padding-bottom: 0px;
            padding-right: 60px;
          }
        }
      }
    }
  });
}

@charset "UTF-8";
// Import ChuckCSS
@import "../../node_modules/ChuckCSS/chuckcss/front-light";

// Import Fontawesome
@import '../../node_modules/@fortawesome/fontawesome-pro/less/fontawesome';
@import '../../node_modules/@fortawesome/fontawesome-pro/less/light';
@import '../../node_modules/@fortawesome/fontawesome-pro/less/regular';
@import '../../node_modules/@fortawesome/fontawesome-pro/less/solid';
@import '../../node_modules/@fortawesome/fontawesome-pro/less/brands';
@fa-font-display: swap;


@import "settings-override";

// Custom fonts (include with cdn)
.open { font-family: 'Open Sans', sans-serif; }
.satisfy { font-family: 'Satisfy', cursive; }

html,
body {
	._media-m-up({
		&.opened-menu {
			overflow: inherit !important;
		}
	});

	&.opened-modal {
	    #main-header,
	    #main-content,
	    #main-footer {
	      ._filter(blur(2px));
	      pointer-events: none;
	    }
	}
}


body {
	.open;
	font-weight:300;

	// Main containers
	.cc-inside {
		._inside(100%);
		padding: 0 20px;

		// Screen > 989px
		._media-m-up({
			._inside(900px);
			padding: 0;
		});

		// Screen > 1189px
		._media-l-up({
			._inside(1200px);
		});
	}

	ol, ul {
		._reset;

		li {
			list-style: none;
		}
	}

	picture img {
		display: block;
	}
}


@import "helpers/hamburger";
@import "helpers/modals";
@import "libraries/flickity";
@import "libraries/fancybox";
@import "website/common";
@import "website/header-footer";
@import "website/post";
@import "website/colors";
@import "website/homepage";
@import "website/videos";
@import "website/quizz";
.listing-page.videos {
  .big-video-container {
    position: relative;
    max-width: 782px;
    max-height: 436px;
    margin-left: auto;
    margin-right: auto;

    i {
      margin: 0;
      position: absolute;
      z-index: 99;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      font-size: 8rem;
      color: #fff;
    }
  }
}

#modal-tirawa-presentation {


  .video-responsive {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
  }

  .video-responsive iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
  }
}
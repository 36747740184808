.content-page {

  // Floating link to open chapters modal
  .chapter-floating-link {
    position: fixed;
    bottom: 15px;
    right: 15px;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 2rem;
    width: 50px;
    height: 50px;
    border-radius: 100%;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);

    ._media-m-up({
      bottom: 50%;
      margin-bottom: -43px;
      width: 86px;
      height: 86px;
      font-size: 4.9rem
    });
  }


  // Author section
  .section-author {

    // Picture + name
    img {
      display: block;
      width: 140px;
      height: 140px;
      overflow: hidden;
      margin: 0 auto 20px;
      border-radius: 100%;
    }

    .author-name {
      font-weight: 400;

      span {
        font-style: italic;
        font-weight: 600;
      }
    }


    // Live post
    .is-live {
      color: @live;
      font-style: italic;
      font-weight: 600;
      font-size: 1.6rem;
      padding-top: 10px;
      text-align: center;

      i {
        font-size: 2rem;
      }
    }

    // H1 title
    .title {
      margin-top: 40px;
      text-transform: uppercase;

      & + h1 {
        font-weight: 400;
      }
    }

    .post-location {
      color: @gray6f;
      font-size: 1.6rem;
      font-style: italic;
      padding-top: 10px;
      display: flex;
      justify-content: center;
      text-align: left;

      i {
        margin-right: 15px;
      }
    }

    iframe {
      margin-top: 20px;
    }
  }


  // Paginations : top and bottom
  .post-pagination {
    padding: 20px 0;

    &.pagination-top {
      padding-bottom: 0;

      ul {
        justify-content: center;

        li.see-all,
        li {
          flex: inherit;
        }

        li {
          &.cc-txt-right,
          &.cc-txt-left {
            min-width: 50px;
          }
        }
      }

      a {
        display: block;
        padding: 20px;
      }
    }

    ul {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-style: italic;
      font-size: 1.6rem;
      font-weight: 400;

      li {
        min-width: 75px;

        &.pagination-left,
        &.pagination-right {

          a[href^='javascript'] {
            pointer-events: none;
            opacity: 0.2;
          }
        }
      }
    }

    // Screen > 767px;
    ._media-s-up({
      padding: 40px 0;

      &.pagination-top {
        padding-bottom: 0;
      }

    });

    // Screen > 989px;
    ._media-m-up({
      padding: 50px 0;

      // Paginations : top and bottom
      &.pagination-bottom {
        ul li {
          flex: 0 0 33%
        }
      }

      &.pagination-top {
        padding-bottom: 0;
      }

      ul a {
        padding: 10px 20px;
      }

    });
  }


  // Backtotop button
  .backtotop-article {
    text-align: center;
    margin-bottom: 20px;

    a {
      padding: 10px 20px;
      display: inline-block;
      font-size: 1.5rem;
      color: @gray6f;
    }
  }

  // Pushes after article
  .pushes {
    position: relative;
    z-index: 2;
    background-color: @gray;

    header {
      text-align: center;
      ._inside(100%);
      padding: 0 20px 20px;

      .h1-like {
        text-transform: uppercase;
      }

      .satisfy {
        font-size: 1.9rem
      }
    }

    // carousel
    .listing {
      padding: 0 20px;
      margin-bottom: 30px;

      .item {
        background-color: white;
      }
    }

    // Screen > 767px
    ._media-s-up({
      header {
        padding-bottom: 40px;

        .satisfy {
          font-size: 2.5rem;
        }
      }
    });

    // Screen > 989px
    ._media-m-up({
      header {
        .satisfy {
          font-size: 3rem;
        }
      }
    });
  }
}
._generateColors(@color:red) {

	// Global class
	.cc-current {
		color: @color;	
	}

	// .body
	.body {
		ol li:before ,
		ul li:before {
			background-color: @color;
		}

		strong,
		a {
			color: @color;
		}
	}

	// Breadccrumb
	.breadcrumb {
		ol {
			li {
				color:@color;
				
				a {
					color: @color
				}
			}
		}
	}

	// blockquote
	blockquote {
		border-left-color: @color;
	}


	/*
		-- POST PAGE --
	*/
	// Wysi content in post page
	.chapter-content {
		a {
			color: @color;
			text-decoration: underline;
		}

		strong {
			color: @color;
		}
	}
	// Active chapters in glossary panel
	#glossary-list {
		a:hover,
		a.active {
			color: @color;

			.glossary-number span {
				color: @color;
			}
		}
	}
	// Link to open glossary panel
	.chapter-floating-link {
		background-color: @color;
	}
	// Pushes after article : links;
	.item {
		a {
			&:hover {
				.item-title span {
					color: @color;
				}
			
				.item-link {
					color: @color;
				}
			}
		}
	}
	// Quizz question
	&.quizz {
		.question-wrapper.chapter-content {
			.body {
				border-left-color: @color;
			}
		}

		.wrap-quizz {
			.form-checkbox {
			
				label:hover {
					color: @color;
				}

				input:checked {
					border-color: @color;
					background-color: @color;
				}
			}

			button[type="submit"] {
				._btn(@color);
			}
		}
	}
}


// Generate All colors
._generateColors(@green); // Default
.color-post-color-blue { ._generateColors(@blue) } //  Blue
.color-post-color-orange { ._generateColors(@orange) } // Orange
.color-post-color-red { ._generateColors(@red) } // Red
.color-post-color-purple { ._generateColors(@purple) } // Purple

// black texts
.cc-black {
	color: black;
}
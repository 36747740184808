/*
	@@ COMMON STYLES
		- Separator with Tirawa logo
		- .btn, button
		- Pano Image at the top of pages
		- Obliques
		- Breadcumb
		- .section
		- Listings / Pagination
		- overlays
		- Wysi texts (.chapter-content)
    - post Youtube
*/
// Separator with Tirawa logo
.sep {
  height: 66px;
  background: transparent url(/images/logo-sep.png) no-repeat center center;
  position: relative;

  &:after,
  &:before {
    content: ' ';
    display: block;
    height: 1px;
    background-color: @graye0;
    width: 37%;
    position: absolute;
    top: 50%;
  }

  &:before {
    left: 0;
  }

  &:after {
    right: 0%;
  }

  ._media-s-up({
    &:before,
    &:after {
      width: 43%;
    }
  });

  ._media-l-up({
    &:before,
    &:after {
      width: 45%;
    }
  });

  // Last sep, inscrease margin-bottom
  #main-content & {
    &:last-child {
      margin-bottom: 60px;
    }
  }
}

// Button, links
a.btn,
button {
  padding: 15px 40px;

  &.arrowed {
    padding: 15px 20px;

    .icon-left {
      margin-right: 20px;
    }

    .icon-right {
      margin-left: 20px;
    }
  }

  &.cc-bg-black {
    ._btn(black);
    color: white;
  }
}

// Pano image : top of pages
.homepage .pano-img {
  height: 65vh;
  min-height: 65vh;
}

.pano-img {
  //height: 30vh;
  min-height: 30vh;

  ._media-l-up({
    height: ~"calc(100vh + 40px)" !important;
    min-height: ~"calc(100vh + 40px)" !important;
  });

  color: white;
  position: relative;
  padding: 80px 20px;
  z-index: 2 !important;

  .cms & {
    height: ~"calc(40vh + 40px)";
    min-height: ~"calc(40vh + 40px)";
  }

  .error404 & {
    height: 100vh;
    min-height: 100vh;
  }

  // Wrapper
  & > section {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: relative;
    z-index: 2;
  }

  .overlay {
    background-color: rgba(0, 0, 0, 0.15);
  }

  h1,
  .h1-like {
    color: white;
    text-transform: uppercase;
    font-size: 3rem;
    line-height: 1.2;
    text-shadow: 2px 2px 2px black;
    margin-top: 20px;
  }

  p {
    text-shadow: 1px 1px 1px black;

    img:not(.logo) {
      max-width: 80px;
    }

    &.post-infos {
      font-weight: 600;
      font-style: italic;
    }

    &.line {
      height: 1px;
      width: 50%;
      max-width: 290px;
      margin: 20px auto;
      background-color: white;
      box-shadow: 1px 1px 1px 0px black;
    }
  }

  // Aroww at the bottom
  .arrow {
    position: absolute;
    bottom: 0px;
    left: 50%;
    margin-left: -40px;
    font-size: 8rem;

    display: none;
    text-shadow: 2px 2px 1px black;

    a {
      display: block;
      color: white;
      padding: 0 20px;

      i {
        display: block;
      }
    }
  }

  ._media-l-up({
    .arrow {
      display: block;
    }
  });

  // Specific for homepage
  .homepage & {
    p {
      max-width: 760px;
      margin: 0 auto;
      padding: 0 30px;
      font-style: italic;
      font-weight: 600;
    }

  }


  // Screen > 767px
  ._media-s-up({
    h1,
    .h1-like {
      font-size: 2.5rem;
    }

    p {
      font-size: 2rem;
    }
  });

  // Screen > 989px
  ._media-m-up({
    h1,
    .h1-like {
      font-size: 2.9rem;
    }

    p {
      img {
        max-width: inherit;
      }

      &.line {
        margin: 30px auto;
      }
    }
  });

  // Screen > 1199px
  ._media-l-up({
    padding-top: 100px;

    h1,
    .h1-like {
      font-size: 3.8rem;
    }
  });
}

// Obliques
.oblic-bottom {
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 ~"calc(100% - 40px)");
}

.oblic-top {
  clip-path: polygon(0 0, 100% 40px, 100% 100%, 0 100%);
}

.oblic-both {
  clip-path: polygon(0 0, 100% 40px, 100% 100%, 0 ~"calc(100% - 40px)");
}

// Breadccrumb
.breadcrumb {
  padding-top: 40px;
  padding-bottom: 40px;

  ol {
    text-align: center;

    li {
      font-style: italic;
      position: relative;
      padding-left: 20px;
      display: inline;


      &:after {
        content: '/';
        color: #868686;
        position: absolute;
        top: -2px;
        left: 8px;
      }

      &:nth-child(1) {
        padding-left: 0;

        &:after {
          content: '';
          display: none;
        }
      }
    }
  }
}

// Sections paddings
.section {
  padding-top: 40px;
  padding-bottom: 40px;

  &.oblic-both,
  &.oblig-top {
    padding-top: 60px;
  }

  &.oblic-both,
  &.oblig-bottom {
    padding-bottom: 60px;
  }

  // Screen > 989px
  ._media-m-up({
    padding-top: 50px;
    padding-bottom: 50px;

    &.oblic-both,
    &.oblig-top {
      padding-top: 90px;
    }

    &.oblic-both,
    &.oblig-bottom {
      padding-bottom: 90px;
    }
  });
}

/*
	Item in listing.
	Used in :
		- Post : Pushes after texts
		- homepage
		- listing pages
*/
.listing {

  .homepage &,
  .listing-page & {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .push {
      flex: 0 0 280px;
      margin: 10px;
    }

    ._media-m-up({
      // justify-content: space-around;

      &.align-left {
        justify-content: flex-start
      }
    });
  }

  .push {
    width: 280px;
    max-width: 280px;
    margin: 0 10px;

    .item {
      background-color: @gray;
      height: 100%;

      a {
        display: flex;
        flex-direction: column;
        height: 100%;

        &:hover {
          .item-title {
            color: @green;

            span {
              color: @green;
            }
          }

          .item-link {
            color: @green;
          }
        }

        // img
        p.backstretch {
          width: 100%;
          flex: 0 0 210px;
          height: 210px;
          position: relative;
        }

        .item-title {
          min-height: 82px;
          overflow: hidden;
          padding: 0 10px 10px;
          ._transition(0.3s, color);

          span {
            color: black;
            display: block;
            font-weight: 600;
            font-size: 1.8rem;
            line-height: 1.5;
          }

          em {
            display: block;
            font-style: italic;
            color: @gray6f;
            font-size: 1.5rem;
            ._truncate(260px);
          }
        }

        .item-descr {
          height: 90px;
          overflow: hidden;
          font-size: 1.5rem;
          line-height: 1.5;
          margin: 15px 0 10px;
          padding: 0 10px;
        }

        .item-link {
          overflow: hidden;
          text-align: center;
          margin-top: auto;
          padding: 0 10px 20px;
        }
      }
    }

    // Specific for video push
    &.push-video {
      p.backstretch {
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        font-size: 8rem;

        i {
          position: relative;
          z-index: 3;
        }

        .overlay {
          background-color: rgba(0, 0, 0, 0.3);
        }
      }
    }

    // Live push (blog article)
    &.is-live {
      p.backstretch {
        span {
          display: block;
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          background: fade(@live, 80%);
          color: white;
          font-weight: 600;
          font-style: italic;
          text-align: center;
          padding: 10px;

          i {
            font-size: 2.5rem;
          }
        }
      }
    }
  }
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 50px 0 0;

  li {
    padding: 0 5px;

    a {
      width: 30px;
      height: 30px;
      border-radius: 100%;
      background-color: transparent;
      display: flex;
      align-items: center;
      justify-content: center;
      ._transition;

      &:hover,
      &.active {
        background-color: @green;
        color: white;
      }
    }
  }
}


// Overlay
.overlay {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}


// Chapter content
.chapter-content {

  .body {
    // Skeleton
    div:not(.country-map):not(.wysimages):not(.backstretch),
    & > h3,
    & > h2,
    & > p:not(.subtitle) {
      ._inside(100%);
      padding: 0 20px;

      // Screen > 989px
      ._media-s-up({
        ._inside(70%);
        max-width: 800px;
        padding: 0;
      });
    }

    & > blockquote {
      margin: 0 20px;

      // Screen > 989px
      ._media-s-up({
        ._inside(70%);
        max-width: 800px;
      });
    }

    & > ul {
      margin: 0 20px;

      // Screen > 989px
      ._media-s-up({
        ._inside(70%);
        max-width: 800px;
        padding-left: 20px;
      });

    }

    // Styles
    p {
      text-align: justify;

      // Introduction : Subtitle;
      &.subtitle {
        .satisfy;
        max-width: 1000px;
        margin: 0 auto 20px;
        padding: 0 20px;
        text-align: center;
        font-size: 2.2rem;

        // Screen > 989px
        ._media-s-up({
          font-size: 3rem;
          margin-bottom: 40px;
        });
      }
    }

    strong {
      font-weight: 600
    }

    a {
      text-decoration: underline;
    }

    ol,
    ul {
      li {
        list-style: none;
        text-align: justify;
        position: relative;
        padding-left: 15px;

        &:before {
          content: ' ';
          display: block;
          position: absolute;
          border-radius: 100%;
          width: 6px;
          height: 6px;
          top: 11px;
          left: 0px;
        }
      }
    }

    h2, h3 {
      margin-bottom: 15px;
    }

    h3 img,
    ul img,
    p img {
      display: block;
      margin: 40px auto 20px !important;
      max-width: 100% !important;
      height: inherit !important;
      float: none !important;
    }

    .content-legend {
      display: block;
      color: #8b9092;
      font-style: italic;
      font-weight: 600;
      text-align: center;
      padding: 0 0 40px;
    }

    .country-map {
      padding: 0 20px;
      max-width: 1000px;
      margin: 0 auto;

      // Screen > 989px
      ._media-m-up({
        padding: 0;
      });
    }
  }

  // Images in Wysiwyg
  .wysi-three-img,
  .wysi-two-img,
  .wysimages {
    margin-bottom: 10px;

    figure {
      position: relative;
      margin: 0 0 10px;
    }

    img {
      display: block;
      margin: 0 !important;
      width: inherit !important;
      height: inherit !important;
    }

    figcaption {
      position: absolute;
      left: 10px;
      bottom: 10px;
      right: 10px;
      font-weight: 600;
      font-style: italic;
      color: white;
      text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.7);
      font-size: 1.3rem;
      line-height: 1.3;
    }

    // Screen > 767px
    ._media-s-up({
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      // AVEC MARGES BLANCHES
      max-width: 60% !important;
      width: auto !important;
      margin: 0 auto 10px;

      figure {
        flex: 0 0 100%;
        //height: 80vh;
        //width: 100vw;
        margin: 0;

        //img {
        //  object-fit: cover;
        //  object-position: center;
        //}

        &.img-half {
          @half: ~"calc(50% - 5px)";
          flex: 0 0 @half;
        }
      }

      figcaption {
        left: 20px;
        bottom: 20px;
        right: 20px;
        font-size: 1.5rem;
      }
    });

    // Screen > 1199px
    ._media-l-up({
      figcaption {
        font-size: 1.7rem;
      }
    });
  }

  .wysi-three-img {
    img {
      width: 100% !important;
      height: 100% !important;
    }
  }

  // 2 small images on the same line
  .wysi-two-img {
    padding-bottom: 15px !important;

    a {
      display: block;

      &:nth-child(1) {
        margin-bottom: 20px !important
      }
    }

    ._media-s-up({
      display: flex;
      justify-content: space-between;

      a {
        max-width: 48% !important;
        flex: 0 0 390px;
        margin: 0px !important;
        height: inherit !important;
        float: none !important;

        &:nth-child(1) {
          margin-bottom: 0px !important
        }
      }
    });
  }

  // 3 small images on the same line
  .wysi-three-img {
    padding-bottom: 15px !important;

    a {
      display: block;

      &:nth-child(1),
      &:nth-child(2) {
        margin-bottom: 20px !important
      }
    }

    ._media-s-up({
      display: flex;
      justify-content: space-between;

      a {
        max-width: 33% !important;
        flex: 0 0 33%;
        margin: 0px !important;
        height: inherit !important;
        float: none !important;

        &:nth-child(1),
        &:nth-child(2) {
          margin-bottom: 0px !important
        }
      }
    });
  }
}

// Youtube video
.post-youtube {
  .h2-like {
    color: black;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 30px;
  }
}


.video-wrapper {
  max-width: 800px;
  margin: 0 auto;

  .video-container {
    position: relative;
    width: 100% !important;
    padding-bottom: 56.25% !important;
    padding-top: 30px !important;
    height: 0;
    overflow: hidden;

    iframe,
    object,
    embed {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}


// SPECIFIC FOR VIDEOS PAGES - Title + Intro above listings
.heading-videos-listing {
  h2 {
    color: @h1[mc];
    text-transform: uppercase;
  }
}
/*! Flickity v2.1.2
https://flickity.metafizzy.co
---------------------------------------------- */

.flickity-enabled {
  position: relative;

  &:focus {
    outline: none;
  }

  .flickity-viewport {
    overflow: hidden;
    position: relative;
    height: 100%;

    .flickity-slider {
      position: absolute;
      width: 100%;
      height: 100%;
    }
  }

  &.is-draggable {
    -webkit-tap-highlight-color: transparent;
    tap-highlight-color: transparent;
    user-select: none;

    .flickity-viewport {
      cursor: move;
      cursor: -webkit-grab;
      cursor: grab;

      &.is-pointer-down {
        cursor: -webkit-grabbing;
        cursor: grabbing;
      }
    }
  }

  /* flickity-fade */
  &.is-fade .flickity-slider > * {
    pointer-events: none;
    z-index: 0;
  }

  &.is-fade .flickity-slider > .is-selected {
    pointer-events: auto;
    z-index: 1;
  }
}


/* ---- flickity-button ---- */

.flickity-button {
  position: absolute;
  background: @primary;
  opacity: 0.4;
  border: none;
  color: white;

  &:hover {
    opacity: 1;
    cursor: pointer;
    background: @primary;
  }

  &:focus {
    outline: none;
  }

  &:active {
    opacity: 1;
  }

  &:disabled {
    opacity: 0.3;
    cursor: auto;
    pointer-events: none;
  }

  // Arrow
  .flickity-button-icon {
    fill: currentColor;
  }
}



/* ---- previous/next buttons ---- */
.flickity-prev-next-button {
  top: 50%;
  width: 44px;
  height: 88px;
  transform: translateY(-50%);
  display: none;
  
  &.previous {
    left: 10px; 
  }

  &.next {
    right: 10px;
  }

  .flickity-button-icon {
    position: absolute;
    left: 20%;
    top: 20%;
    width: 60%;
    height: 60%;
  }

  ._media-s-up({
    display: block;
  });

  ._media-m-up({
    &.previous {
      left: 20px;
    }
    &.next {
      right: 20px;
    }
  });
}



/* ---- page dots ---- */
.flickity-page-dots {
  position: absolute;
  width: 100%;
  bottom: -25px;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  line-height: 1;
  
  .dot {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin: 0 8px;
    background: @primary;
    border-radius: 50%;
    opacity: 0.25;
    cursor: pointer;
    
    &.is-selected {
      opacity: 1;
    }
  }
}
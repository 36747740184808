
.quizz {

	.question-wrapper.chapter-content {
		background-color: @gray;
		padding:20px;
		margin-top: 30px;

		&:nth-child(1) {
			margin-top: 0;
		}
		
		&.error {
			background-color: #fff4f4;

			.body {
				border-left-color: @red;

				strong, u, p {
					color: @red;
				}
			}
		}

		.body {
			border-left:2px solid @green;
			padding-left:20px;

			p {
				width: inherit;
				max-width: inherit;
				font-size: 1.6rem;
				font-weight: 400;
				padding:0;
			}
		}
		

		.nb-good-answers {
			margin-bottom: 10px;

			em {
				display: inline-block;
				font-size: 1.2rem;
				color:@primary;
				border-radius: 2px;
			}
		}

		.form-checkbox {
			display: block;
			padding: 5px 10px;

			// Display good answers
			&.good-answer {
				margin:10px 0;
				background-color: #cfe7cf;

				label {
					input {
						border-color: #7eac5f;

						&:checked {
							border-color: #7eac5f;
							background-color: #7eac5f
						}
					}
				}
			}

			label {
				display: block;
				font-size: 1.6rem;
				padding: 0;
				font-weight: 300;
				color:#2A2A2A;
				._transition(0.3s, color);


				input {
					width: 18px;
					height: 18px;
					box-shadow: none;
					top: 3px;
					border-color: #2A2A2A;

					&:checked {
						border-color: #2A2A2A;
						background-color: #2A2A2A
					}
				}
			}

			.percent-result {
				display: none;
				font-style: italic;
				font-size: 1.2rem;
				color:lighten(@text[mc], 10%);
				font-weight: 700;
			}
		}
	}

	.swal2-container {
		
		.swal2-popup {
			padding: 15px 20px;
		}
		.swal2-title {
			font-size: 2.5rem;
		}
		.swal2-html-container {
			font-size: 1.5rem;
			padding:15px 0;
		}
		.swal2-confirm.swal2-styled {
			._btn(@red);
			outline: none;
			font-size: 1.4rem;
		}
	}


	// QUIZZ RESULTS
	#main-content .wrap-form-results {
		display: none;

		.sep {
			margin: 50px auto 0;
		}
	}
	.form-results {
		text-align: center;
		padding:20px;
		background-color:@gray;

		.circle {
			margin:30px auto;
	        position: relative;
	        width: 200px;
	        height: 200px;

	        strong {
	            position: absolute;
	            top:0;
	            bottom: 0;
	            left: 0;
	            right: 0;
	            display: flex;
	            justify-content:center;
	            align-items:center;
	            font-size: 3rem;
	            font-weight: 700;
	        }
	    }
	    .left {
	    	padding-bottom: 40px;
	    }
	    .right {
	    	text-align: justify;
	    }
	    .alert-success {
	    	text-align: left;
	    }
	}



	._media-m-up({
		.question-wrapper.chapter-content {
			.body {
				img {
					max-width: 50% !important;
					margin: 20px 0 !important;
				}
			}
		}

		.form-results {
			display: flex;
			align-items: center;
			flex-wrap: wrap;

			& > p {
				flex: 0 0 100%;
				text-align: left;
				padding-left:45px;
			}

			.left {
				flex:0 0 300px;
				padding-right: 40px;
			}

		    .right {
		    	flex: 1;
		    	padding: 0px 0px 40px;
		    	text-align: left;
		    }

		    .alert-success {
		    	flex: 0 0 100%;
		    	font-size: 1.6rem;
		    }
		}
	});

}